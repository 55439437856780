import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import BlockContent from "../components/block-content";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { getCategories } from "../lib/helpers";
import Landing from "../components/landing";
import PropTypes from "prop-types";

import { useForm } from "react-hook-form";
import Form from "../components/form";
const ContactPage = ({ data, errors }) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  const {contact} = data
  return (
    <Layout>
      <SEO title="Kapcsolat" />
      <Container>
      {contact && contact.mainImage && <Landing mainImage={contact.mainImage}/>}
        {contact && contact._rawBody && <BlockContent blocks={contact._rawBody || []} />}
        <Form />
      </Container>
    </Layout>
  );
};
export default ContactPage;

ContactPage.propTypes = {
  data: PropTypes.shape({
    _rawBody: PropTypes.string,
    frontmatter: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
  errors: PropTypes.array,
};

export const query = graphql`
  query ContactPageQuery {
    contact: sanityContact {
      seo {
        title
        metaKeyWords
        metaDescription
      }
      title
      mainImage {
        asset {
          _id
        }
        alt
      }
      _rawBody
    }
  }
`;
